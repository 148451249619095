







































































































































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import ManagementLayout from '@/layouts/management/ManagementLayout.vue';
import SearchBar from '@/components/common/SearchBar.vue';
import ManagementList from '@/components/management/ManagementList.vue';
import ManagementItemFormTemplate from '@/components/management/ManagementItemFormTemplate.vue';
import { SubjectStores } from '@/store/subjects';
import { GeneralListItem, QuestionBankListItem } from '@/models/management';
import { FormStores } from '@/store/forms';
import { ChapterItemFormTemplateOutput, FolderItem } from '@/models/miscellaneous';
import QuestionBankManagementList from '@/components/management/QuestionBankManagementList.vue';
import ModalFormLayout from '@/layouts/common/ModalFormLayout.vue';
import { ChapterStores } from '@/store/chapters';
import ChapterItemFormTemplate from '@/components/management/ChapterItemFormTemplate.vue';
import { QuestionBankStores } from '@/store/question_banks';
import { DropdownItem } from '@/models/dropdown';
import { ChapterDisplayItem, FetchChapterParams, GeneralChapterItem } from '@/models/chapters';
import { QuestionStores } from '@/store/questions';
import moment from 'moment';
import { QuestionTypeNames } from '@/utils/constants';
import { PaginationDetails } from '@/models/generic';
import { FetchQuestionBankQuestionParams } from '@/models/questions';
import { SubjectItemSearchParams } from '@/models/subjects';
import { FormItemSearchParams } from '@/models/forms';
@Component({
  components: {
    Page,
    ManagementLayout,
    SearchBar,
    ManagementList,
    ManagementItemFormTemplate,
    QuestionBankManagementList,
    ModalFormLayout,
    ChapterItemFormTemplate,
  },
})
export default class QuestionBanks extends Vue {
  currentFolders: FolderItem[] = [];
  currentSubjects: GeneralListItem[] = [];
  currentForms: GeneralListItem[] = [];
  currentChapters: ChapterDisplayItem[] = [];
  currentQuestionBanks: DropdownItem[] = [];
  currentQuestions: QuestionBankListItem[] = [];
  isCreateFlowActive = false;
  isDeleteButtonDisabled = true;
  // Subjects
  subjectCreateStore = SubjectStores.create;
  subjectListStore = SubjectStores.list;
  subjectDetailStore = SubjectStores.details;
  // Forms
  formCreateStore = FormStores.create;
  formDetailStore = FormStores.details;
  formListStore = FormStores.list;
  // Chapters
  chapterListStore = ChapterStores.list;
  chapterCreateStore = ChapterStores.create;
  chapterDetailStore = ChapterStores.detail;
  // Question Bank List
  questionBankListStore = QuestionBankStores.list;
  // Question
  questionListStore = QuestionStores.list;
  questionDetailStore = QuestionStores.detail;
  // State
  questionStateStore = QuestionStores.state;

  // Checkbox Selection
  subjectCheckboxes: number[] = [];
  formCheckboxes: number[] = [];
  chapterCheckboxes: number[] = [];
  questionCheckboxes: number[] = [];

  // Pagination Details
  currentKeyword = ``;
  currentPage = 1;
  currentLimit = 10;
  currentTotalCount = 0;
  isPaginationReset = false;
  isPageSpecified = false;

  get isCurrentRouteManagement() {
    return this.$route.path === `/question_banks`;
  }

  get folderNames() {
    return this.currentFolders;
  }

  get subjects() {
    return this.currentSubjects;
  }

  get forms() {
    return this.currentForms;
  }

  get chapters() {
    return this.currentChapters;
  }

  get questionBanks() {
    return this.currentQuestionBanks;
  }

  get questions() {
    return this.currentQuestions;
  }

  get modalTitle() {
    return `Choose a question type to add`;
  }

  get formErrorText() {
    return `Error: Level name cannot include any special characters. Example: !, @, #, $, %, ^,&, *`;
  }

  mounted() {
    if (this.questionStateStore.questionBankManagementFolderState.length > 0 && this.questionStateStore.isModified) {
      // Refetch state from API depending on the previous folder state
      this.currentFolders = this.questionStateStore.questionBankManagementFolderState;
      switch(this.currentFolders.length) {
        case 2: {
          // Containing Subjects and Subject Name. Fetch Subjects and levels
          this.currentSubjects = this.questionStateStore.subjectData;
          this.currentPage = this.questionStateStore.currentPageState[this.currentFolders.length];
          this.currentLimit = this.questionStateStore.currentLimitState[this.currentFolders.length];
          this.currentTotalCount = this.questionStateStore.currentTotalCountState[this.currentFolders.length];
          this.currentKeyword = this.questionStateStore.currentKeywordState[this.currentFolders.length];
          this.isPageSpecified = true;
          break;
        }
        case 3: {
          // Containing Subjects, Subject name and Level
          this.currentSubjects = this.questionStateStore.subjectData;
          this.currentForms = this.questionStateStore.formData;
          this.currentPage = this.questionStateStore.currentPageState[this.currentFolders.length];
          this.currentLimit = this.questionStateStore.currentLimitState[this.currentFolders.length];
          this.currentTotalCount = this.questionStateStore.currentTotalCountState[this.currentFolders.length];
          this.currentKeyword = this.questionStateStore.currentKeywordState[this.currentFolders.length];
          this.isPageSpecified = true;
          break;
        }
        case 4: {
          // Containing Subjects, Subject name, Level and Questions
          this.currentSubjects = this.questionStateStore.subjectData;
          this.currentForms = this.questionStateStore.formData;
          this.currentChapters = this.questionStateStore.chapterData;
          this.currentPage = this.questionStateStore.currentPageState[this.currentFolders.length];
          this.currentLimit = this.questionStateStore.currentLimitState[this.currentFolders.length];
          this.currentTotalCount = this.questionStateStore.currentTotalCountState[this.currentFolders.length];
          this.currentKeyword = this.questionStateStore.currentKeywordState[this.currentFolders.length];
          this.isPageSpecified = true;
          break;
        }
        default: {
          break;
        }
      }
      this.retrieveData();
    } else if (this.questionStateStore.questionBankManagementFolderState.length > 0 && !this.questionStateStore.isModified) {
      this.currentFolders = this.questionStateStore.questionBankManagementFolderState;
      // Get back previous states from stores
      this.currentSubjects = this.questionStateStore.subjectData;
      this.currentForms = this.questionStateStore.formData;
      this.currentChapters = this.questionStateStore.chapterData;
      this.currentQuestions = this.questionStateStore.questionData;
      this.currentPage = this.questionStateStore.currentPageState[this.currentFolders.length];
      this.currentLimit = this.questionStateStore.currentLimitState[this.currentFolders.length];
      this.currentTotalCount = this.questionStateStore.currentTotalCountState[this.currentFolders.length];
      this.currentKeyword = this.questionStateStore.currentKeywordState[this.currentFolders.length];
      this.isPageSpecified = true;
      // Specifically for creating a chapter with question bank listing
      if (this.currentFolders.length === 3) {
        this.questionBankListStore.retrieveQuestionBanks({ formid: this.currentFolders[this.currentFolders.length - 1].id });
      }
    } else {
      this.currentFolders.push({id: -1, name: 'Subjects'});
      this.retrieveData();
    }
  }

  beforeDestroy() {
    this.saveCurrentState();
  }

  @Watch(`subjectListStore.response`)
  setSubjects() {
    if (this.subjectListStore.response) {
      this.currentSubjects = [];
      this.currentTotalCount = this.subjectListStore.response.totalCount;
      this.subjectListStore.response.objects.forEach((item) => {
        this.currentSubjects.push({
          id: item.id,
          name: item.subjectName,
        });
      });
      this.questionStateStore.updateQuestionBankManagementSubjectState(this.currentSubjects);
    }
  }

  @Watch(`formListStore.response`)
  setForms() {
    if (this.formListStore.response) {
      this.currentForms = [];
      this.currentTotalCount = 0;
      this.formListStore.response.objects.forEach((data) => {
        this.currentForms.push({
          id: data.id,
          name: data.formName,
        });
      });
      this.questionStateStore.updateQuestionBankManagementFormState(this.currentForms);
    }
  }

  @Watch(`chapterListStore.response`)
  setChapters() {
    if (this.chapterListStore.response) {
      this.currentChapters = [];
      this.currentTotalCount = this.chapterListStore.response.totalCount;
      this.chapterListStore.response.objects.forEach((data) => {
        this.currentChapters.push({
          id: data.id,
          name: data.name,
          questionType: data.questionType === QuestionTypeNames.mcq ? 'Multiple Choice' : data.questionType === QuestionTypeNames.essay ? 'Essay' : 'None', 
          questionbankid: data.questionbankid,
        });
      });
      this.questionStateStore.updateQuestionBankManagementChapterState(this.currentChapters);
    }
  }

  @Watch(`questionBankListStore.response`)
  setQuestionBanks() {
    if (this.questionBankListStore.response) {
      this.currentQuestionBanks = [];
      this.questionBankListStore.response.objects.forEach((data) => {
        const questionTypeText = data.questionType === QuestionTypeNames.mcq ? 'Multiple Choice' : data.questionType === QuestionTypeNames.essay ? 'Essay' : 'None';
        this.currentQuestionBanks.push({
          text: `${data.name} (${questionTypeText})`,
          value: data.id.toString(),
          uniqueKey: `${data.name}_${data.id}`,
        });
      });
    }
  }

  @Watch(`questionListStore.response`)
  setQuestions() {
    if (this.questionListStore.response) {
      this.currentQuestions = [];
      this.questionListStore.response.objects.forEach((data) => {
        this.currentQuestions.push({
          id: data.id,
          publicId: data.quesId,
          name: data.quesName,
          createdBy: data.createdBy,
          modifiedBy: data.updatedBy,
          createdDate: moment(data.createdAt).toDate(),
          modifiedDate: moment(data.updatedAt).toDate(),
        });
      });
      this.questionStateStore.updateQuestionBankManagementQuestionState(this.currentQuestions);
      this.currentTotalCount = this.questionListStore.totalSize;
    }
  }

  @Watch(`subjectCreateStore.response`)
  @Watch(`subjectDetailStore.response`)
  updateCurrentList() {
    /*
        Create Response contains data
        Update / Delete Response does not contain data
        */
    if (this.subjectCreateStore.response || this.subjectDetailStore.response) {
      this.isCreateFlowActive = false;
      this.retrieveData();
    }
  }

  @Watch(`formCreateStore.response`)
  @Watch(`formDetailStore.response`)
  updateCurrentFormList() {
    /*
      Create Response contains data
      Update / Delete Response does not contain data
    */
    if (this.formCreateStore.response || this.formDetailStore.response) {
      this.isCreateFlowActive = false;
      this.retrieveData();
    }
  }

  @Watch(`chapterCreateStore.response`)
  @Watch(`chapterDetailStore.response`)
  updateCurrentChapterList() {
    if (this.chapterCreateStore.response || this.chapterDetailStore.response) {
      this.isCreateFlowActive = false;
      this.retrieveData();
    }
  }

  @Watch(`questionDetailStore.response`)
  updateCurrentQuestionList() {
    if (this.questionDetailStore.response) {
      this.isCreateFlowActive = false;
      this.retrieveData();
    }
  }

  redirectToCreateQuestion() {
    this.$router.push({
      path: `/questions/create`,
    });
  }

  redirectToImportQuestions() {
    this.$router.push({
      path: `/questions/import`,
    });
  }

  redirectToQuestionBankManagement() {
    if (this.$route.path !== `/question_banks`) {
      this.$router.push({
        path: `/question_banks`,
      });
    }
  }

  redirectToQuestionBankListing() {
    if (this.$route.path !== `/question_banks/listing`) {
      this.$router.push({
        path: `/question_banks/listing`,
      });
    }
  }

  redirectToEditQuestions(id: number) {
    this.$router.push({
      path: `/questions/${id}/edit`,
    });
  }

  // Create Functions
  createSubject(payload: string) {
    this.subjectCreateStore.createNewSubject(payload);
    this.isCreateFlowActive = false;
  }

  createLevel(payload: string) {
    // Find the current level
    const currentSubject = this.folderNames[this.folderNames.length - 1];
    this.formCreateStore.createNewForm({ subjectId: currentSubject.id, formName: payload });
    this.isCreateFlowActive = false;
  }

  createChapter(payload: ChapterItemFormTemplateOutput) {
    this.isCreateFlowActive = false;
    this.chapterCreateStore.createNewChapter({
      name: payload.value,
      questionBankId: parseInt(payload.dropdownItem.value),
    });
  }

  // Edit Functions
  updateSelectedSubject(payload: GeneralListItem) {
    this.subjectDetailStore.updateCurrentSubject({ id: payload.id, subjectName: payload.name });
  }

  updateSelectedLevel(payload: GeneralListItem) {
    this.formDetailStore.updateCurrentForm({ id: payload.id, formName: payload.name });
  }

  updateSelectedChapter(payload: GeneralChapterItem) {
    this.chapterDetailStore.updateChapterData(payload);
  }

  // Delete Functions
  deleteSelectedSubject(payload: GeneralListItem) {
    this.subjectDetailStore.deleteCurrentSubject({ id: payload.id, subjectName: payload.name });
  }

  deleteSelectedLevel(payload: GeneralListItem) {
    this.formDetailStore.deleteCurrentForm({ id: payload.id, formName: payload.name });
  }

  deleteSelectedChapter(payload: GeneralChapterItem) {
    this.chapterDetailStore.deleteChapterDataById(payload.id);
  }

  deleteSelectedQuestion(payload: QuestionBankListItem) {
    this.questionDetailStore.deleteQuestionBankQuestionById(payload.id);
  }

  // Folder Route function
  addFolderRouteForSubjects(payload: GeneralListItem) {
    this.saveCurrentState();
    this.currentKeyword = ``;
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.folderNames.push({ ...payload });
    this.isCreateFlowActive = false;
    this.retrieveData();
  }

  addFolderRouteForForms(payload: GeneralListItem) {
    this.saveCurrentState();
    this.folderNames.push({ ...payload });
    this.currentKeyword = ``;
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.isCreateFlowActive = false;
    // For Chapters dropdown
    this.questionBankListStore.retrieveQuestionBanks({ formid: payload.id });
    this.retrieveData();
  }

  addFolderRouteForChapters(payload: GeneralChapterItem) {
    this.saveCurrentState();
    this.currentKeyword = ``;
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.folderNames.push({ ...payload });
    this.isCreateFlowActive = false;
    this.retrieveData();
  }

  //Pagination
  updatePagination(payload: PaginationDetails) {
    this.currentPage = payload.page;
    this.currentLimit = payload.limit;
    this.retrieveData();
  }

  resetFolders(payload: FolderItem[]) {
    this.currentFolders = payload;
    this.currentPage = this.questionStateStore.currentPageState[this.currentFolders.length];
    this.currentLimit = this.questionStateStore.currentLimitState[this.currentFolders.length];
    this.currentKeyword = this.questionStateStore.currentKeywordState[this.currentFolders.length];
    this.isCreateFlowActive = false;
    this.isPageSpecified = true;
    this.saveCurrentState();
    this.retrieveData();
  }

  retrieveData() {
    switch(this.currentFolders.length) {
        case 1: {
          // Base State: Containing Subjects only. Fetch Subjects only
          const payload: Partial<SubjectItemSearchParams> = {
            page: this.currentPage,
            limit: this.currentLimit,
          };
          if (this.currentKeyword.length > 0) {
            payload.keyword = this.currentKeyword;
          }
          this.subjectListStore.retrieveSubjects(payload);
          break;
        }
        case 2: {
          // Containing Subjects and Subject Name. Fetch Subjects and levels
          const payload: Partial<FormItemSearchParams> = {
              subjectid: this.currentFolders[this.currentFolders.length - 1].id
          };
          this.formListStore.retrieveForms(payload);
          break;
        }
        case 3: {
          // Containing Subjects, Subject name and Level
          const payload: Partial<FetchChapterParams> = {
            page: this.currentPage,
            limit: this.currentLimit,
            form_id: this.currentFolders[this.currentFolders.length - 1].id,
          };

          if (this.currentKeyword.length > 0) {
            payload.keyword = this.currentKeyword;
          }

          this.chapterListStore.retrieveChapters(payload);
          break;
        }
        case 4: {
          // Containing Subjects, Subject name, Level and Questions
          const params: Partial<FetchQuestionBankQuestionParams> = {
            chapter_id: this.currentFolders[this.currentFolders.length - 1].id,
            page: this.currentPage,
            limit: this.currentLimit,
          };

          if (this.currentKeyword.length > 0) {
            params.keyword = this.currentKeyword;
          }
          this.questionListStore.retrieveQuestionBankQuestionsWithParams(params);
          break;
        }
        default: {
          break;
        }
      }
  }

  // Checkbox
  deleteSelectedSubjects(payload: number[]) {
    this.subjectCheckboxes = payload;
    this.isDeleteButtonDisabled = this.subjectCheckboxes.length > 0 ? false: true;
  }

  deleteSelectedForms(payload: number[]) {
    this.formCheckboxes = payload;
    this.isDeleteButtonDisabled = this.formCheckboxes.length > 0 ? false: true;
  }

  deleteSelectedChapters(payload: number[]) {
    this.chapterCheckboxes = payload;
    this.isDeleteButtonDisabled = this.chapterCheckboxes.length > 0 ? false: true;
  }

  deleteSelectedQuestions(payload: number[]) {
    this.questionCheckboxes = payload;
    this.isDeleteButtonDisabled = this.questionCheckboxes.length > 0 ? false : true;
  }

  deleteSelectedItems() { 
    switch(this.currentFolders.length) {
      case 2: {
        // Delete Multiple Subjects
        break;
      }
      case 3: {
        // Delete Multiple Forms
        break;
      }
      case 4: {
        // Delete multiple courses
        break;
      }
      default: {
        break;
      }
    }
  }

  // Search
  searchItem(payload: string) {
    this.currentPage = 1;
    this.isPaginationReset = true;
    this.currentKeyword = payload;
    this.retrieveData();
  }

  // Pagination
  resetPaginationStatus(payload: boolean) {
    this.isPaginationReset = payload;
  }

  resetCurrentPageStatus(payload: boolean) {
    this.isPageSpecified = payload;
  }

  // Save State
  saveCurrentState() {
    this.questionStateStore.saveQuestionBankManagementFolderState(this.currentFolders);
    this.questionStateStore.saveQuestionBankManagementSubjectState(this.currentSubjects);
    this.questionStateStore.saveQuestionBankManagementFormState(this.currentForms);
    this.questionStateStore.saveQuestionBankManagementChapterState(this.currentChapters);
    this.questionStateStore.saveQuestionBankManagementQuestionState(this.currentQuestions);
    this.questionStateStore.saveQuestionBankManagementCurrentPage({ folderLevel: this.currentFolders.length, value: this.currentPage });
    this.questionStateStore.saveQuestionBankManagementCurrentLimit({ folderLevel: this.currentFolders.length, value: this.currentLimit });
    this.questionStateStore.saveQuestionBankManagementCurrentTotalCount({ folderLevel: this.currentFolders.length, value: this.currentTotalCount });
    this.questionStateStore.saveQuestionBankManagementCurrentKeyword({ folderLevel: this.currentFolders.length, value: this.currentKeyword });
  }
}
